// export const ECOPLOT_API = 'http://localhost:8000/api'
// export const ECOPLOT_PRO = 'https://localhost:3000'

export const ECOPLOT_API = 'https://ecoplotapi.besec.co.jp/api'
export const ECOPLOT_PRO = 'https://ecoplotpro.besec.co.jp'

// export const ECOPLOT_PRO_URL = process.env.ECOPLOT_PRO_URL || 'https://localhost:3000'
// export const ECOPLOT_API_URL = process.env.ECOPLOT_API_URL || 'http://localhost:8000'

export const CORRELATION_API = `${ECOPLOT_API}/correlation/`
export const DECOMPOSITION_API = `${ECOPLOT_API}/decompose/`
