import { ECOPLOT_API } from '../../constants/urls'
import Vue from 'vue'
const _ = require('lodash')
const axios = require('axios')

export default {
  namespaced: true,
  state: {
    serverAuthenticationToken: '',
    user: null,
    project: null,
    tempProject: { name: 'New Project', description: 'Project Description' },
    overlay: false,
    modal: null,
    captureMode: false,
    recordingStatus: 'stop',
    isGifing: false,
    isGifingCount: 0,
    isActivateProduct: false,
    error: null,
    countErrorLogin: 0,
    isUpdating: false,
    updateImageNetcdf: false,
    countIsUpdating: 0,
    updateFuncMap: false,
    imageUpload: null,
    loadingProcess: false,
    renderContinue: 0,
    scatterQueryData: false,
    initProject: 0,
    fileFormat: 'mp4',
    isOnlineProduct: false,
    isActivateOutOfdate: false,
    coutAccAwsS3Update: 0,
    isProjectShare: false,
    permisionRealtime: true,
    permissionShare:'read',
    sidePanelSelected: null,
    layerSelectedOpenProject: {},
  },
  mutations: {
    UPDATE_LAYER_SELECTED_OPEN_PROJECT(state, payload) {
      state.layerSelectedOpenProject = payload
    },
    UPDATE_PERMISION_SHARE(state, payload) {
      state.permissionShare = payload
    },
    UPDATE_PERMISION_REALTIME(state, payload) {
      state.permisionRealtime = payload
    },
    UPDATE_IS_PROJECT_SHARE(state, payload) {
      state.isProjectShare = payload
    },
    SET_SIDE_PANEL_SELECTED(state, payload) {
      state.sidePanelSelected = payload
    },
    CHANGE_SERVER_TOKEN(state, payload) {
      state.serverAuthenticationToken = payload
    },
    CHANGE_USER(state, payload) {
      state.user = payload.user
    },
    CHANGE_PROJECT(state, payload) {
      state.project = payload.project
    },
    UPDATE_PROJECT(state, payload) {
      if (!state.project) return
      state.project = _.merge({}, state.project, payload)
    },
    CHANGE_TEMP_PROJECT(state, payload) {
      state.tempProject = payload.tempProject
    },
    UPDATE_TEMP_PROJECT(state, payload) {
      if (!state.tempProject) return
      state.tempProject = _.merge({}, state.tempProject, payload)
    },
    SET_OVERLAY(state, payload) {
      state.overlay = payload
    },
    SET_MODAL(state, payload) {
      if (payload === null) {
        state.modal = null
      } else {
        state.modal = { ...state.modal, ...payload }
      }
    },
    SET_CAPTURE_MODE(state, payload) {
      state.captureMode = payload
    },
    UPDATE_COUNT_ACC_ASWS3_UPDATE(state, payload) {
      state.coutAccAwsS3Update++
    },
    SET_RECORDING_STATUS(state, payload) {
      state.recordingStatus = payload
    },
    SET_GIFING_STATUS(state, payload) {
      state.isGifingCount++
      state.isGifing = payload
    },
    // START CRUD shared person of project
    ADD_SHARED_PERSON(state, payload) {
      let old = state.project.shared_persons
      old.push(payload)
      Vue.set(state.project, 'shared_persons', old)
    },
    UPDATE_SHARED_PERSON(state, payload) {
      let old = state.project.shared_persons
      let index = old.findIndex((d) => d.id === payload.id)
      old.splice(index, 1, payload)
      Vue.set(state.project, 'shared_persons', old)
    },
    DELETE_SHARED_PERSON(state, { id }) {
      let old = state.project.shared_persons
      old = old.filter((p) => p.id !== id)
      Vue.set(state.project, 'shared_persons', old)
    },
    SET_PRODUCT_KEY_STATUS(state, payload) {
      state.isActivateProduct = payload
    },
    SET_PRODUCT_KEY_OUT_OF_DATE(state, payload) {
      state.isActivateOutOfdate = payload
    },
    // END CRUD shared person of project
    SET_ERROR(state, payload) {
      state.error = payload
      state.countErrorLogin++
    },
    UPDATE_COUNT_ERROR_LOGIN(state, payload) {
      state.countErrorLogin++
    },
    SET_STATUS_FILE_EXECUTE(state, value) {
      state.isUpdating = value
    },
    SET_STATUS_FUNC_MAP(state, value) {
      state.updateFuncMap = value;
      state.countIsUpdating++;
    },
    SET_STATUS_FILE_NETCDF(state, value) {
      state.updateImageNetcdf = value
      state.countIsUpdating++
    },
    SET_PROJECT_THUMBNAILS(state, value) {
      state.imageUpload = value
    },
    SET_STATUS_LOADING_DATA(state, value) {
      state.loadingProcess = value
    },
    SET_STATUS_TIMEPLAYER(state) {
      state.renderContinue++
    },
    SET_STATUS_SCATTER_QUERY_DATA(state, value) {
      state.scatterQueryData = value
    },
    SET_FILE_FORMAT_VIDEO(state, value) {
      state.fileFormat = value
    },
    INIT_PROJECT(state) {
      state.initProject++
    },
    RESET_STATE(state) {
      state.project = null
      state.isProjectShare = false
      state.permisionRealtime = true
      state.tempProject = { name: 'New Project', description: 'Project Description' }
    },
  },
  actions: {
    async GET_USER({ dispatch, commit }, payload) {
      let token = localStorage.getItem('django-authentication-token')

      if (!token) {
        localStorage.removeItem('django-authentication-token')
        localStorage.removeItem('accept-pro-policy')
        commit('CHANGE_USER', { user: null })
        commit('UPDATE_COUNT_ERROR_LOGIN')
        return
      }

      try {
        const response = await axios.get(`${ECOPLOT_API}/users/current/`, { headers: { Authorization: `Token ${token}` } })
        if (!response.data.is_paid) {
          commit('CHANGE_USER', { user: null })
          commit('SET_ERROR', 'You are using FREE account which cannot be used to access Ecoplot Pro.')
          throw ''
        }
        localStorage.setItem('accept-pro-policy', response.data.accept_pro_policy)
        commit('CHANGE_USER', { user: response.data })
      } catch (error) {
        localStorage.removeItem('django-authentication-token')
        localStorage.removeItem('accept-pro-policy')
        commit('CHANGE_USER', { user: null })
      }
      commit('UPDATE_COUNT_ERROR_LOGIN')
    },
    async LOGIN({ dispatch, commit }, payload) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/login/`, payload)
        localStorage.setItem('django-authentication-token', response.data.token)
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Login failed!')
      }
    },
    async FACEBOOK_LOGIN({ dispatch, commit }, payload) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/facebook-login/`, payload)
        localStorage.setItem('django-authentication-token', response.data.token)
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Login failed!')
      }
    },
    async GOOGLE_LOGIN({ dispatch, commit }, payload) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/google-login/`, payload)
        localStorage.setItem('django-authentication-token', response.data.token)
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Login failed!')
      }
    },

    async UPDATE_USER({ dispatch, commit }, data) {
      try {
        const response = await axios.put(`${ECOPLOT_API}/users/current/`, data, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Update user failed!')
      }
    },

    async GOOGLE_LINK({ dispatch, commit }, data) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/users/current/google/`, data, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Google link failed!')
      }
    },
    async GOOGLE_UNLINK({ dispatch, commit }, data) {
      try {
        const response = await axios.delete(`${ECOPLOT_API}/users/current/google/`, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Google unlink failed!')
      }
    },
    async FACEBOOK_LINK({ dispatch, commit }, data) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/users/current/facebook/`, data, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Facebook link failed!')
      }
    },
    async FACEBOOK_UNLINK({ dispatch, commit }, data) {
      try {
        const response = await axios.delete(`${ECOPLOT_API}/users/current/facebook/`, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Facebook unlink failed!')
      }
    },

    async CHANGE_AVATAR({ dispatch, commit }, data) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/users/current/avatar/`, data, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Change avatar failed!')
      }
    },
    async CHANGE_COVER({ dispatch, commit }, data) {
      try {
        const response = await axios.post(`${ECOPLOT_API}/users/current/cover/`, data, { headers: { Authorization: `Token ${localStorage.getItem('django-authentication-token')}` } })
        dispatch('GET_USER')
      } catch (error) {
        commit('SET_ERROR', 'Change cover failed!')
      }
    },

    async LOGOUT({ dispatch, commit }, payload) {
      localStorage.removeItem('django-authentication-token')
      localStorage.removeItem('accept-pro-policy')
      dispatch('GET_USER')
    },
  },
}
