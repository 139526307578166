import useAppConfig from '@/@core/app-config/useAppConfig'
import Plotly from 'plotly.js-dist'
const _ = require('lodash')

export const ThemeConfig = {
  computed: {
    theme: {
      get() {
        const { skin } = useAppConfig()
        return skin.value
      },
      set(val) {
        let { skin } = useAppConfig()
        skin = val
      },
    },
    isDark() {
      return this.theme === 'dark'
    },
  },
}

export const CustomLayout = {
  mixins: [ThemeConfig],
  computed: {
    initProject() {
      return this.$store.state.ecoplot.initProject
    },
    flagTheme() {
      return `${this.theme}__${JSON.stringify(this.chartFont)}`
    },
    chartSettings() {
      return this.$store.getters['settings/chart']
    },
    chartFont() {
      return {
        // family: this.chartSettings.chartFontFamily,
        // size: this.chartSettings.chartTextSize,
        color: this.chartSettings.chartTextColor,
      }
    },
    lightLayout() {
      return {
        font: this.chartFont,
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        modebar: {
          bgcolor: 'transparent',
          color: 'rgba(0,0,0,0.5)',
          activecolor: 'rgba(0,0,0,1)',
        },
        xaxis: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        xaxis1: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        xaxis2: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        xaxis3: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        xaxis4: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis1: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis2: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis3: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis4: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis5: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis6: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis7: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
        yaxis8: { gridcolor: '#eeeeee', zerolinecolor: '#b4b7bd' },
      }
    },
    darkLayout() {
      return {
        font: this.chartFont,
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        modebar: {
          bgcolor: 'transparent',
          color: 'rgba(255,255,255,0.5)',
          activecolor: 'rgba(255,255,255,1)',
        },
        xaxis: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        xaxis1: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        xaxis2: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        xaxis3: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        xaxis4: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis1: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis2: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis3: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis4: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis5: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis6: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis7: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
        yaxis8: { gridcolor: '#42465d', zerolinecolor: '#b4b7bd' },
      }
    },
    themeLayout() {
      if (this.isDark) return this.darkLayout
      else return this.lightLayout
    },
  },
  watch: {
    flagTheme() {
      try {
        if (!Plotly || !this.$refs.chart || (this.$refs.chart && this.$refs.chart.style && (this.$refs.chart.style.visibility == 'hidden' || this.$refs.chart.style.display == 'none'))) return
        let updateLight = {
          // 'font.family': this.chartFont.family,
          // 'font.size': this.chartFont.size,
          'font.color': this.chartFont.color,
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          'modebar.bgcolor': 'transparent',
          'modebar.color': 'rgba(0,0,0,0.5)',
          'modebar.activecolor': 'rgba(0,0,0,1)',
          'xaxis.gridcolor': '#eeeeee',
          'xaxis.zerolinecolor': '#b4b7bd',
          'xaxis1.gridcolor': '#eeeeee',
          'xaxis1.zerolinecolor': '#b4b7bd',
          'xaxis2.gridcolor': '#eeeeee',
          'xaxis2.zerolinecolor': '#b4b7bd',
          'xaxis3.gridcolor': '#eeeeee',
          'xaxis3.zerolinecolor': '#b4b7bd',
          'xaxis4.gridcolor': '#eeeeee',
          'xaxis4.zerolinecolor': '#b4b7bd',
          'yaxis.gridcolor': '#eeeeee',
          'yaxis.zerolinecolor': '#b4b7bd',
          'yaxis1.gridcolor': '#eeeeee',
          'yaxis1.zerolinecolor': '#b4b7bd',
          'yaxis2.gridcolor': '#eeeeee',
          'yaxis2.zerolinecolor': '#b4b7bd',
          'yaxis3.gridcolor': '#eeeeee',
          'yaxis3.zerolinecolor': '#b4b7bd',
          'yaxis4.gridcolor': '#eeeeee',
          'yaxis4.zerolinecolor': '#b4b7bd',
          'yaxis5.gridcolor': '#eeeeee',
          'yaxis5.zerolinecolor': '#b4b7bd',
          'yaxis6.gridcolor': '#eeeeee',
          'yaxis6.zerolinecolor': '#b4b7bd',
          'yaxis7.gridcolor': '#eeeeee',
          'yaxis7.zerolinecolor': '#b4b7bd',
          'yaxis8.gridcolor': '#eeeeee',
          'yaxis8.zerolinecolor': '#b4b7bd',
        }
        let updateDark = {
          // 'font.family': this.chartFont.family,
          // 'font.size': this.chartFont.size,
          'font.color': this.chartFont.color,
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          'modebar.bgcolor': 'transparent',
          'modebar.color': 'rgba(255,255,255,0.5)',
          'modebar.activecolor': 'rgba(255,255,255,1)',
          'xaxis.gridcolor': '#42465d',
          'xaxis.zerolinecolor': '#b4b7bd',
          'xaxis1.gridcolor': '#42465d',
          'xaxis1.zerolinecolor': '#b4b7bd',
          'xaxis2.gridcolor': '#42465d',
          'xaxis2.zerolinecolor': '#b4b7bd',
          'xaxis3.gridcolor': '#42465d',
          'xaxis3.zerolinecolor': '#b4b7bd',
          'xaxis4.gridcolor': '#42465d',
          'xaxis4.zerolinecolor': '#b4b7bd',
          'yaxis.gridcolor': '#42465d',
          'yaxis.zerolinecolor': '#b4b7bd',
          'yaxis1.gridcolor': '#42465d',
          'yaxis1.zerolinecolor': '#b4b7bd',
          'yaxis2.gridcolor': '#42465d',
          'yaxis2.zerolinecolor': '#b4b7bd',
          'yaxis3.gridcolor': '#42465d',
          'yaxis3.zerolinecolor': '#b4b7bd',
          'yaxis4.gridcolor': '#42465d',
          'yaxis4.zerolinecolor': '#b4b7bd',
          'yaxis5.gridcolor': '#42465d',
          'yaxis5.zerolinecolor': '#b4b7bd',
          'yaxis6.gridcolor': '#42465d',
          'yaxis6.zerolinecolor': '#b4b7bd',
          'yaxis7.gridcolor': '#42465d',
          'yaxis7.zerolinecolor': '#b4b7bd',
          'yaxis8.gridcolor': '#42465d',
          'yaxis8.zerolinecolor': '#b4b7bd',
        }

        Plotly.relayout(this.$refs.chart, this.isDark ? updateDark : updateLight)
      } catch {}
    },
    initProject() {
      try {
        if (!this.$refs.chart) return
        if (this.$refs.chart.layout) {
          if (this.$refs.chart.layout.title) delete this.$refs.chart.layout.title
          if (this.$refs.chart.layout.xaxis.title) delete this.$refs.chart.layout.xaxis.title
          if (this.$refs.chart.layout.xaxis2.title) delete this.$refs.chart.layout.xaxis2.title
          if (this.$refs.chart.layout.xaxis3.title) delete this.$refs.chart.layout.xaxis3.title
          if (this.$refs.chart.layout.xaxis4.title) delete this.$refs.chart.layout.xaxis4.title
          if (this.$refs.chart.layout.yaxis.title) delete this.$refs.chart.layout.yaxis.title
          if (this.$refs.chart.layout.yaxis2.title) delete this.$refs.chart.layout.yaxis2.title
          if (this.$refs.chart.layout.yaxis3.title) delete this.$refs.chart.layout.yaxis3.title
          if (this.$refs.chart.layout.yaxis4.title) delete this.$refs.chart.layout.yaxis4.title
          if (this.$refs.chart.layout.yaxis5.title) delete this.$refs.chart.layout.yaxis5.title
          if (this.$refs.chart.layout.yaxis6.title) delete this.$refs.chart.layout.yaxis6.title
          if (this.$refs.chart.layout.yaxis7.title) delete this.$refs.chart.layout.yaxis7.title
          if (this.$refs.chart.layout.yaxis8.title) delete this.$refs.chart.layout.yaxis8.title
        }
      } catch (error) {}
    },
  },
  methods: {
    getLayout(extraLayout) {
      if (!this.isDark) {
        return _.merge({}, extraLayout, this.lightLayout)
      } else {
        return _.merge({}, extraLayout, this.darkLayout)
      }
    },
  },
}
